<template>
  <div>
    <div class="loadingWrap" id="loading-box2" style="">
      <div class="dim">
        <div v-if="isLoading" class="loadingBox">
          <!-- <img src="kiki.gif" alt="error-5"> -->
          <div class="msg-box">
            <div class="loading-circle"></div>
            <span>
                        잠시만
                        <br>
                        기다려 주세요.
                        <br><br>
                        부점장 / 관리자 페이지로 이동 중입니다.
                     </span>
          </div>
        </div>


        <section v-else class="flex items-center h-full">
          <div class="container">
            <div class="content_box text-center max-w-2xl mx-auto">

              <img src="https://lxpcms.kbstar.com/error/lamu404.png" alt="error-5" class="h-72 mb-2 mx-auto">
              <h1 class="font-bold sm:text-[150px] text-4xl mb-2" style="margin: 0;"> </h1>
              <h3 class="text-gray-800 sm:text-2xl text-4xl font-bold mb-4" style="margin: 0;">
                권한이 없거나 잘못된 접근으로<br/>부점장/관리자 페이지로 이동할 수 없습니다.
              </h3>
              <!-- <p class="text-gray-700 font-medium text-xl leading-relaxed" style="margin: 0;">Oopsie-daisy! The page
                  you requested seems to have taken a vacation. Return to the homepage and continue your journey.</p> -->
              <img src="https://lxpcms.kbstar.com/error/lxp2.png" alt="img-not-found" class="mb-2 mx-auto" style="width: 16%; margin-top:1rem">

            </div>
          </div>
        </section>


      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from 'vuex';
import {ACT_GET_ADMIN_AUTH, TOKEN_ADMKEY} from '@/store/modules/auth/auth'
import { useRoute } from 'vue-router'
// import {isAdmin} from '@/assets/js/modules/auth/auth-common';
import {saveToken} from '@/assets/js/localstorage.service'
import {isSuccess} from '@/assets/js/util'
import {onMounted, ref} from 'vue';
  export default {
    name: 'GoAdmin',
    setup(){
      const route = useRoute()
      const store = useStore()

      const isLoading = ref(true);
      // isAdmin()
      console.log(route.query);

      onMounted(() => {
        if(!route.query.admref) {

          // alert('필수 입력항목이 없습니다. 잘못된 페이지로 연결되었습니다.')

          isLoading.value = false;
          closeWin()
          // window.location.href = '/app/notfound'
          return;
        }
        const qstr = toQryStr(route.query)
        console.log(qstr)
        // console.log(store.state.auth.session.isAdmin)
        // if( !store.state.auth.session.isAdmin ) {
        //   console.log
        //   closeWin()
        //   // window.location.href = '/app/notfound'
        //   return
        // }
        store.dispatch(`auth/${ACT_GET_ADMIN_AUTH}`).then(res => {
          if( isSuccess(res) && res.adminToken) {
            console.log(res.adminToken)
            saveToken(TOKEN_ADMKEY , res.adminToken)
            if(qstr) {
              window.location.href = '/admin' + route.query.admref + '?' + qstr
            } else {
              window.location.href = '/admin' + route.query.admref
            }
          } else {
            // alert('관리자 페이지로 이동할 수 없습니다.')
            isLoading.value = false;
            closeWin()
            // window.location.href = '/app/notfound'
          }
        }).catch(e => {
          console.error(e)
          // alert('관리자 페이지로 이동할 수 없습니다.');
          isLoading.value = false;
          closeWin()
          // window.location.href = '/app/notfound'
        })
      })

      const toQryStr = (qrys) => {
        return Object.entries(qrys).filter(e => e[0] !== 'admref').map(e => e.join('=')).join('&');
      } 

      const closeWin = () => {
        console.log('창 닫기')
        window.open('','_self').close()
      }

      return {
        isLoading
      }
    }
  }
  </script>


<style scoped>

@font-face {
  font-family: KBFGDisplay;
  src: url("/files/fonts/KBFGDisplayM.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
.loadingBox{position:fixed;width:370px;z-index:15;left:50%;top:50%;transform:translate(-50%,-50%);box-sizing: border-box;background-image: url("/files/images/kiki.gif");background-size: auto; background-repeat: no-repeat;height: 350px;background-position-x: center;}
.loadingBox .msg-box{position:relative;top:46%; padding:80px 15px 15px;background-color:#fff;border:2px solid #292929;box-shadow: 0 0 13px rgba(0,0,0,0.3);border-radius:10px;text-align: center;}
.loadingBox .msg-box:after{content:"";display:block;clear:both;}
.loadingBox .msg-box span{font-family:KBFGDisplay; display: inline; font-weight: 400;}


.loading-circle {position:absolute; left:50%; top:23%; transform:translate(-50%, -50%); width:40px; height:40px;box-sizing: border-box; border:6px solid #f3f3f3; border-top:6px solid rgb(244, 148, 164); border-radius:50em; transition:all .2s;
  animation-name:spinCircle;
  animation-duration:.8s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

.loadingWrap .dim {position:fixed; left:0; top:0; width:100%; height:100%; background:#f7f4f0;}
.loadingWrap .loading-circle {position:absolute; transform:translate(-50%, -142px);}

img{
  left:auto;
  right:auto;
  display: inline;
}
@keyframes spinCircle {
  from {
    transform:translate(-50%, -50%) rotate(0);
  }
  to {
    transform:translate(-50%, -50%) rotate(360deg);
  }
}


@media screen and (max-width: 460px){
  .loadingBox{
    background-size: 6rem;
    background-position-y: 70px;
  }

  .loadingBox .msg-box{
    font-size: 80%;
    padding: 70px 10px 10px;
    margin: 0 40px auto;
  }

  img{
    width: 50%;
  }

  .loading-circle{
    width: 30px;
    height: 30px;
  }
}


body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

h1,
h3 {
  font-size: inherit;
  font-weight: inherit;
}

.flex {
  display: flex;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.h-full {
  height: 100%;
  background-color: #f7f4f0;
}

.content_box {
  color: rgb(84, 80, 69);
  font-family: KBFGDisplay;
  overflow-wrap: break-word;
}

.items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.max-w-2xl {
  max-width: 42rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.h-72 {
  height: 18rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.text-9xl {
  font-size: 10rem;
  line-height: 1;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 4rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}


.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.leading-relaxed {
  line-height: 1.625;
}

@media screen and (max-width: 460px){
  .text-9xl{
    font-size: 5rem
  }

  .text-4xl{
    font-size: 1.3rem;
  }

  .icon{
    height: 30%;
  }
}
</style>